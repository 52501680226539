<template>
  <div class="surprise-modal" ref="modal" style=" position: relative;">
    <video
      @loadedmetadata="logCurrentSrc"
      id="rosette-video"
      ref="videoElementRef"
      class="rosette-video"
      playsinline
      autoplay
      muted
    >
      <source src="~@/assets/rosettes/rosette-surprise-crate.mp4" />
    </video>
    <audio ref="audioElementRef">
      <source src="~@/assets/rosettes/rosette-voice.wav" type="audio/wav" />
    </audio>
    <div v-if="showScore">
      <div class="congratulation-message">
        TEBRİKLER
      </div>
      <div class="score-text">
        <strong>{{ getRosetteRewardPoint }}</strong
        ><br />Puan
      </div>
      <div class="modal-info">
        <img
          style="margin-right: 5px;width: 16px; height: 16px;"
          src="~@/assets/rosettes/rosette-modal-info.svg"
        />

        <div v-html="getRosetteRewardText" />
      </div>
    </div>
  </div>
</template>
<script>
import RosetteInfo from '@/mixins/rosetteInfo.js';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'RosettesDetail',
  mixins: [RosetteInfo],
  data() {
    return {
      showScore: false,
      surpriseAwardPopupContent: '',
    };
  },
  computed: {
    getRosetteRewardPoint() {
      return this.rosetteRewardPoint;
    },
    getRosetteRewardText() {
      return this.rosetteRewardText;
    },
  },
  methods: {
    logCurrentSrc() {
      const video = this.$refs.videoElementRef;
      video.addEventListener('timeupdate', () => {
        const thresholdTime = 4.5;
        this.showScore = video.currentTime >= thresholdTime;
      });
    },
    getRosetteSurpriseAwardPopup() {
      StaticContent.getStaticContent('RosetteSurpriseAwardPopup').then(res => {
        const { content } = res.data.Data;
        this.surpriseAwardPopupContent = content;
      });
    },
  },
  async mounted() {
    this.getRosetteSurpriseAwardPopup();
    this.setRosetteRewardPoint();

    this.$nextTick(() => {
      const videoElement = this.$refs.videoElementRef;
      const audio = this.$refs.audioElementRef;
      const modalElement = this.$refs.modal;

      if (modalElement) {
        modalElement.style.height = '395px';
        modalElement.style.overflow = 'hidden';
      }

      if (videoElement) {
        videoElement.setAttribute('playsinline', 'playsinline');
        videoElement.muted = true;
        videoElement.play();
        audio.play();
      }
    });
  },
};
</script>

<style scoped lang="scss">
.surprise-modal {
  background-image: url('~@/assets/rosettes/rosette-surprise-modal.svg');
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 335px;
  height: 420px;
  .title {
    color: #ffa300;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 65px;
  }
  .content {
    color: #23303d;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
  }
}
.rosette-crate-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.score-text {
  transform: translateX(-50%);
  position: absolute;
  color: #aa5a10;
  text-align: center;
  line-height: 1;
  font-size: 20px;
  font-weight: 600;
  top: 26%;
  left: 50.5%;
}
.congratulation-message {
  background: linear-gradient(180deg, #ffd487 0%, #ffa300 60.53%);
  background-clip: text;
  -webkit-bacground-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  top: 30px;
  left: 50%;
}
.modal-info {
  color: rgba(35, 48, 61, 0.8);
  transform: translateX(-50%);
  position: absolute;
  line-height: 1.3;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  z-index: 9999;
  width: 290px;
  bottom: 35px;
  left: 50%;
}
.rosette-video {
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
